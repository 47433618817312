import Vue from 'vue'

export default {
  SET_STUDENT (state, payload) {
    state.student = Object.assign({}, state.student, payload) //precisa ser assim para manter a reatividade
  },
  SET_STUDENT_CNH (state, payload) {
    const cnhIndex = state.student.cnhs.findIndex((cnh) => cnh.id === payload.id)
    state.student.cnhs.splice(cnhIndex, 1, payload) //precisa ser assim para manter a reatividade
  },
  ADD_STUDENT_CNH (state, payload) {
    state.student.cnhs.push(payload) //precisa ser assim para manter a reatividade
  },
  DELETE_STUDENT_CNH (state, payload) {
    const cnhIndex = state.student.cnhs.findIndex((cnh) => cnh.id === payload)
    state.student.cnhs.splice(cnhIndex, 1) //precisa ser assim para manter a reatividade
  },
  SET_STUDENTS (state, students) {
    state.students = students
  },
  REMOVE_RECORD (state, itemId) {
    const userIndex = state.students.data.findIndex((u) => u.id === itemId)
    if (userIndex >= 0) {
      state.students.data.splice(userIndex, 1)
      state.students.total = state.students.total - 1
    }
  },

  // MÓDULO DE AGENDAS
  SET_STUDENT_LIST_SCHEDULES (state, payload) {
    state.studentListSchedules = payload
  },
  DELETE_STUDENT_LIST_SCHEDULES (state, payload) {
    payload.map(sd => {
      const idx = state.studentListSchedules.data.findIndex(o => o.id === sd.id)
      if (idx >= 0) {
        state.studentListSchedules.data.splice(idx, 1) //precisa ser assim para manter a reatividade
        state.studentListSchedules.total = state.studentListSchedules.total - 1
      }
    })
  },
  ADD_STUDENT_LIST_SCHEDULES (state, payload) {
    state.studentListSchedules = state.studentListSchedules.concat(payload)
    state.studentListSchedules.total = state.studentListSchedules.total + payload.length
  },
  UPDATE_STUDENT_LIST_SCHEDULES (state, payload) {
    const idx = state.studentListSchedules.data.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.studentListSchedules.data, idx, payload)
  },

  //AQUI NAO UTILIZO O RETORNO DA API, NESTE CASO FAÇO A MUDANÇA ANTES DE ENVIAR PARA A API
  UPDATE_MASSIVE_SCHEDULES (state, payload) {
    payload.schedules.map(a => {
      const index1 = state.student.schedules.findIndex(o => o.id === a.id)
      if (index1 >= 0) Vue.set(state.student.schedules, index1, Object.assign({}, a, payload.changes))
    })
  },
  DELETE_SCHEDULES (state, payload) {
    payload.map(a => {
      const idx = state.student.schedules.findIndex(o => o.id === a.id)
      if (idx >= 0) state.student.schedules.splice(idx, 1)
    })
  }
}
