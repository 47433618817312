import axios from '@/axios.js'

export default {
  store (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/student`, payload)
        .then((resp) => {
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },

  changeReminder ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/student/changeReminder/${payload.id}`, payload)
        .then((resp) => {
          commit('SET_STUDENT', resp.data)
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateStudent ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/student/${payload.id}`, payload)
        .then((resp) => {
          commit('SET_STUDENT', resp.data)
          resolve(resp)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchStudents ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/students`, payload)
        .then((response) => {
          commit('SET_STUDENTS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchStudent ({ commit }, studentId) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/student/${studentId}`)
        .then((res) => {
          commit('SET_STUDENT', res.data)
          resolve(res)
        })
        .catch((error) => { reject(error) })
    })
  },
  sendSnapShot (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/webcam-change-photo`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeRecord ({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/student/${userId}`)
        .then((response) => {
          commit('REMOVE_RECORD', userId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // MÓDULO DE GENDAS (busca todos os agendamentos de exame independentemente de tipo)
  fetchStudentExamSchedules ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/student`, payload)
        .then((response) => {
          commit('SET_STUDENT_LIST_SCHEDULES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
