import state from './moduleStudentManagementState.js'
import mutations from './moduleStudentManagementMutations.js'
import actions from './moduleStudentManagementActions.js'
import getters from './moduleStudentManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

