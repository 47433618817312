const sortPhonesBy = ['mobile', 'phone', 'other']
const addressEmpty = {
  logradouro: null,
  street: null,
  number: null,
  complement: null,
  neighborhood: null,
  zip_code: null,
  observation: null,
  type_address: 'res',
  state_id: null,
  city_id: null,
  country: 1 // Brasil
}
export default {
  getStudent (state) {

    if (!state.student.id) return {}  // CASO TENTE USAR O GETTER SEM UM ALUNO NO STATE (PODE OCORRER COM O ATRASO DO DISPATCH)

    const student = JSON.parse(JSON.stringify(state.student))
    if (student.intended_services && student.intended_services.length) {
      student.intended_services = student.intended_services[0]
    }

    student.groups = student.groups ? student.groups.map(g => g.id) : []

    if (student.emails && student.emails.length) {
      student.email = student.emails[0].email
    } else {
      student.email = null
    }

    if (!student.address) {
      student.address = addressEmpty
    } else {
      student.address.country = 1
    }
    student.registration_date = student.registration_date ? student.registration_date.slice(0, 10) : ''
    student.opening_date_renach_form_number = student.opening_date_renach_form_number ? student.opening_date_renach_form_number.slice(0, 10) : ''
    student.date_expiration_renach_form_number = student.date_expiration_renach_form_number ? student.date_expiration_renach_form_number.slice(0, 10) : ''
    student.date_of_birth = student.date_of_birth ? student.date_of_birth.slice(0, 10) : ''
    student.ladv_emission = student.ladv_emission ? student.ladv_emission.slice(0, 10) : ''

    //AJUSTANDO OS TELEFONES VAZIOS
    if (!student.phones.filter(phone => phone.type === 'mobile').length) {
      student.phones.unshift({id: null, phone: null, type: 'mobile' })
    }
    if (!student.phones.filter(phone => phone.type === 'phone').length) {
      student.phones.push({id: null, phone: null, type: 'phone' })
    }
    if (!student.phones.filter(phone => phone.type === 'other').length) {
      student.phones.push({id: null, phone: null, type: 'other' })
    }
    //ORDENANDO OS TELEFONES NA SEQUÊNCIA ABAIXO
    student.phones.sort((a, b) => sortPhonesBy.indexOf(a.type) - sortPhonesBy.indexOf(b.type))

    // TENANT -----------------------------------------------------------------------------------
    if (!student.tenant) {
      student.tenant = { address: addressEmpty }
    } else if (!student.tenant.address) {
      student.tenant.address = addressEmpty
    } else {
      student.tenant.address.country = 1
    }

    if (student.tenant.emails && student.tenant.emails.length) {
      student.tenant.email = student.tenant.emails[0].email
    } else {
      student.tenant.email = null
    }

    if (!student.tenant.phones) {
      student.tenant.phones = [
        {type: 'mobile', phone: null},
        {type: 'phone', phone: null},
        {type: 'other', phone: null}
      ]
    } else {
      // AJUSTANDO OS TELEFONES VAZIOS
      if (!student.tenant.phones.filter(phone => phone.type === 'mobile').length) {
        student.tenant.phones.unshift({id: null, phone: null, type: 'mobile' })
      }
      if (!student.tenant.phones.filter(phone => phone.type === 'phone').length) {
        student.tenant.phones.push({id: null, phone: null, type: 'phone' })
      }
      if (!student.tenant.phones.filter(phone => phone.type === 'other').length) {
        student.tenant.phones.push({id: null, phone: null, type: 'other' })
      }
      student.tenant.phones.sort((a, b) => sortPhonesBy.indexOf(a.type) - sortPhonesBy.indexOf(b.type))
    }

    // CNHS -----------------------------------------------------------------------------------

    // ORDENA CNHS POR DATA DE EMISSÃO
    student.cnhs.sort((a, b) => {
      // Emissão date_last_cnh
      return new Date(b.date_last_cnh).getTime() - new Date(a.date_last_cnh).getTime()
    })

    // INCLUI cityCnhOptions PARA O V-FOR TER ESTADO E CIDADE PARA CADA CNH
    if (student.cnhs.length) {
      student.cnhs = student.cnhs.map(cnh => {
        cnh.date_last_cnh = cnh.date_last_cnh ? cnh.date_last_cnh.slice(0, 10) : ''
        cnh.validity_cnh = cnh.validity_cnh ? cnh.validity_cnh.slice(0, 10) : ''
        cnh.delivery_date = cnh.delivery_date ? cnh.delivery_date.slice(0, 10) : ''
        cnh.toxicological = cnh.toxicological ? cnh.toxicological.slice(0, 10) : ''
        cnh.validity_toxicological = cnh.validity_toxicological ? cnh.validity_toxicological.slice(0, 10) : ''
        cnh.cityCnhOptions = []
        return cnh
      })
    }

    // GUARANTOR -----------------------------------------------------------------------------------
    if (!student.guarantor) {
      student.guarantor = { address: addressEmpty, type: 'FISICA' }
    } else if (!student.guarantor.address) {
      student.guarantor.address = addressEmpty
    } else {
      student.guarantor.address.country = 1
    }

    if (student.guarantor) {
      student.guarantor.date_of_birth_guarantor = student.guarantor.date_of_birth_guarantor ? student.guarantor.date_of_birth_guarantor.slice(0, 10) : ''
    }

    if (student.guarantor.email) {
      student.guarantor.email = student.guarantor.email.email
    }

    if (!student.guarantor.phones) {
      student.guarantor.phones = [
        {type: 'mobile', phone: null},
        {type: 'phone', phone: null},
        {type: 'other', phone: null}
      ]
    } else {
      // AJUSTANDO OS TELEFONES VAZIOS
      if (!student.guarantor.phones.filter(phone => phone.type === 'mobile').length) {
        student.guarantor.phones.unshift({id: null, phone: null, type: 'mobile' })
      }
      if (!student.guarantor.phones.filter(phone => phone.type === 'phone').length) {
        student.guarantor.phones.push({id: null, phone: null, type: 'phone' })
      }
      if (!student.guarantor.phones.filter(phone => phone.type === 'other').length) {
        student.guarantor.phones.push({id: null, phone: null, type: 'other' })
      }
      student.guarantor.phones.sort((a, b) => sortPhonesBy.indexOf(a.type) - sortPhonesBy.indexOf(b.type))
    }

    return student
  }

}
